@font-face {
	font-family: 'page-symbols';
	src: url('./page-symbols.woff2') format('woff2'),
	url('./page-symbols.woff') format('woff'),
	url('./page-symbols.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/*
.page-symbol:before{
    display: inline-block;
    font-family: page-symbols;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.page-symbol.four-pointed-star:before{content:'✦';}
.page-symbol.filled-circle:before{content:'●';}
.page-symbol.dotted-circle:before{content:'◌';}
.page-symbol.dagger:before{content:'†';}
.page-symbol.floral-heart-bullet-reversed:before{content:'☙';}
*/

.page-symbol, .psm {
	font-family: page-symbols;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.psm.top2 {
	position: relative;
	top: 2px;
}

.psm.outline {
	text-shadow: -1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.psm.white-rose, ul.white-rose li:before {
	color: #fff;
	text-shadow: -1px -1px 0 #888, -1px 1px 0 #888, 1px 1px 0 #888, 1px -1px 0 #888, 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.psm.red-rose, ul.red-rose li:before {
	color: #ff8787;
	text-shadow: -1px -1px 0 #b00, -1px 1px 0 #b00, 1px 1px 0 #b00, 1px -1px 0 #b00, 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.psm.yellow-rose, ul.yellow-rose li:before {
	color: #ffed85;
	text-shadow: -1px -1px 0 #971, -1px 1px 0 #971, 1px 1px 0 #971, 1px -1px 0 #971, 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.psm.pink-rose, ul.pink-rose li:before {
	color: #f787ff;
	text-shadow: -1px -1px 0 #b0a, -1px 1px 0 #b0a, 1px 1px 0 #b0a, 1px -1px 0 #b0a, 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.psm.purple-rose, ul.purple-rose li:before {
	color: #dea4ff;
	text-shadow: -1px -1px 0 #8524bb, -1px 1px 0 #8524bb, 1px 1px 0 #8524bb, 1px -1px 0 #8524bb, 2px 2px 3px rgba(0, 0, 0, 0.2);
}
