:root {
	color-scheme: light dark;
}

@page-color: #fffaf4;

h1, h2, h3, h4, h5, h6 {
	text-shadow: 1px 2px 9px rgba(0, 0, 0, 0.3);
}

a {
	color: #ff7898;
}

a:hover, a:active {
	color: #94abff;
}

hr {
	border-color: #653f0a;

	&.gray1 {
		border-color: #3d3d3d;
	}
}

.darkRed1, .god-sub {
	color: #ff7d8c;
}

.darkRed2, .god {
	color: #ff8492;
}

.darkRed3, .blood {
	color: #ff93a0;
}

.red1 {
	color: #ffa6ad;
}

.red2 {
	color: #ffbdc3;
}

.red3 {
	color: #ffd2d9;
}

.darkOrange1 {
	color: #ff9f73;
}

.darkOrange2, .jesus {
	color: #ffac85;
}

.darkOrange3 {
	color: #ffbe9b;
}

.orange1 {
	color: #ffcfb5;
}

.orange2 {
	color: #ffd5c4;
}

.orange3 {
	color: #ffe2d5;
}

.darkGold1, .jesus-sub {
	color: #ffb651;
}

.darkGold2 {
	color: #fdbf69;
}

.darkGold3 {
	color: #fdc985;
}

.gold1 {
	color: #fdd5a0;
}

.gold2 {
	color: #fddeb6;
}

.gold3 {
	color: #ffe8c7;
}

.darkBlue1, .michael-sub {
	color: #909bfc;
}

.darkBlue2 {
	color: #9ea3ff;
}

.darkBlue3 {
	color: #aab3ff;
}

.blue1 {
	color: #bbbeff;
}

.blue2 {
	color: #ced6ff;
}

.blue3 {
	color: #d6dcff;
}

.darkGreen1 {
	color: #62d562;
}

.darkGreen2, .pio, .padre-pio, .st-padre-pio, .saint, .bonaventure, .st-bonaventure {
	color: #79db79;
}

.darkGreen3 {
	color: #98e798;
}

.green1 {
	color: #bcf6bc;
}

.green2 {
	color: #c9ffc9;
}

.green3 {
	color: #d0ffd0;
}

.darkPink1 {
	color: #ff7add;
}

.darkPink2, .mary {
	color: #fc83da;
}

.darkPink3, .rosa-mystica {
	color: #ff92e0;
}

.pink1 {
	color: #fdace7;
}

.pink2 {
	color: #fcbee6;
}

.pink3 {
	color: #fdc7ee;
}

.darkPurple1 {
	color: #c27bfc;
}

.darkPurple2, .joseph, .st-joseph {
	color: #ce93ff;
}

.darkPurple3 {
	color: #d7a7ff;
}

.purple1 {
	color: #dfbafd;
}

.purple2 {
	color: #e5c8ff;
}

.purple3 {
	color: #e9d6fd;
}

.darkOcean1 {
	color: #48aeff;
}

.darkOcean2, .michael, .gabriel, .rafael, .angel, .st-michael, .st-gabriel, .st-rafael, .st-raphael, .st-angel {
	color: #63b7ff;
}

.darkOcean3 {
	color: #80c2ff;
}

.ocean1 {
	color: #99ccff;
}

.ocean2 {
	color: #aad3ff;
}

.ocean3 {
	color: #b8d8ff;
}

.primary {
	color: #9686ff;
}

.black {
	color: #fff;
}

.white {
	color: #000;
}

@header-title-filter: sepia(1) hue-rotate(250grad) saturate(3) brightness(2.5);


@keyframes star1-dark {
	0% {
		transform: rotate(0);
	}
	6% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes star2-dark {
	0% {
		transform: rotate(45deg);
	}
	6% {
		transform: rotate(405deg);
	}
	100% {
		transform: rotate(405deg);
	}
}

@keyframes star3-dark {
	0% {
		transform: rotate(116deg);
	}
	6% {
		transform: rotate(476deg);
	}
	100% {
		transform: rotate(476deg);
	}
}

.page {
	background: #201e1d;
	color: @page-color;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, .3);

	> .header {
		color: #a492fd;
		text-shadow: 1px 1px 3px #23211f;

		a:hover, a:active, .link:hover, .link:active {
			color: #f670ae;
		}

		.background {
			border-color: #553d97;
		}

		.headerTitle {
			.star {
				filter: @header-title-filter;
			}
			.star1 {
				animation-name: star1-dark;
			}
			.star2 {
				animation-name: star2-dark;
			}
			.star3 {
				animation-name: star3-dark;
			}
		}

		.menuButton {
			border: 1pt solid #5940ce;

			.button {
				.bar {
					background: #8672e3;
					box-shadow: 2px 2px 3px #23211f;
				}
			}

			&:hover, &:active {
				.button {
					.bar {
						background: #f670ae;
					}
				}
			}
		}

		.quickMenu {
			.item {
				.subMenu {
					background: #0a0628;
					border: 1.5pt solid #9a77d7;
					color: #cab3ff;
					text-shadow: 1px 1px 3px #2f2e2d;
					box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5), inset 0 0 5px 1px rgba(0, 0, 0, 0.2), 0px 0px 0px 1pt rgba(50, 0, 150, 0.3);

					> div {
						&.active {
							color: #ef23d1;
						}
					}
				}
			}
		}

	}
}

.pageLogo {
	.mariaCrown {
		filter: drop-shadow(2px 2px 3px #0a0317) @header-title-filter;
	}
}

.pageIntro {
	color: #957ffd;
	border-color: #a176ff;
}

.mainMenu {
	border-color: #664b95;
	box-shadow: 5px 5px 14px #1e1c1a;
	color: #cab3ff;
	text-shadow: 1px 1px 3px #201f1c;

	.link {
		&:not(:last-child) {
			border-bottom: 1pt solid #6f44bb;
		}

		&.main {
			background-color: #311b05;
		}

		&.sub {
			background-color: #1b0531;
			padding-left: 30px;
		}

		a {
			&.active {
				color: #ef23d1;
			}

			&:hover, &:active {
				color: #f670ae;
				text-decoration: none;
			}
		}
	}
}

.navigationIcon {
	filter: sepia(1) hue-rotate(225grad) saturate(3) brightness(1.7);
}

.pageNavigation {
	.ruler {
		border-color: #825e31;
	}

	.navItem {
		color: #fa88e8;

		&:hover:not(.active) {
			background: rgba(109, 80, 139, 0.5);
		}

		&.active {
			background: rgba(138, 14, 51, 0.5);
		}
	}
}

.tableOfContents {
	.ruler {
		border-color: #825e31;
	}

	.tocItem {
		color: #fa88e8;

		&:hover:not(.active) {
			background: rgba(109, 80, 139, 0.5);
		}

		&.active {
			background: rgba(138, 14, 51, 0.5);
		}
	}
}

.tocHeader {
	border-color: rgba(200, 109, 253, 0.47);
}


div.pinBoard {
	&.alert {
		background: rgba(47, 5, 5, 0.45);
		border-color: #af0707;

		.close {
			color: #f25858;
		}
	}

	&.warning {
		background: rgba(45, 27, 3, 0.45);
		border-color: #bb9500;

		.close {
			color: #d59c44;
		}
	}

	&.success {
		background: rgba(3, 50, 3, 0.45);
		border-color: #181;

		.close {
			color: #393;
		}
	}

	&.info {
		background: rgba(13, 20, 60, 0.45);
		border-color: #2d58ad;

		.close {
			color: #7391ff;
		}
	}
}

.linkMenu {
	color: #cab3ff;
	text-shadow: 1px 1px 3px #282232;
}

@border-and-joiners-filter: sepia(1) hue-rotate(380grad) saturate(2) brightness(.7);

.pageBorder {
	filter: @border-and-joiners-filter;
}

.pageCorner {
	filter: @border-and-joiners-filter;
}

div[class^='splitter'], div[class*=' splitter']{
	filter: @border-and-joiners-filter;
}

div[class^='joiner'], div[class*=' joiner']{
	filter: @border-and-joiners-filter;
}

/*
.splitter-1, .splitter-1-bg {
	filter: @border-and-joiners-filter;
}

.splitter-7 {
	filter: @border-and-joiners-filter;
}

.splitter-11 {
	filter: @border-and-joiners-filter;
}

.splitter-13-short {
	filter: @border-and-joiners-filter;
}

.joiner-1 {
	filter: @border-and-joiners-filter;
}

.joiner-7 {
	filter: @border-and-joiners-filter;
}

.joiner-8 {
	filter: @border-and-joiners-filter;
}

.joiner-11 {
	filter: @border-and-joiners-filter;
}

.joiner-12 {
	filter: sepia(1) hue-rotate(380grad) saturate(2) brightness(1);
}

.joiner-13-short {
	filter: @border-and-joiners-filter;
}
*/

.content {
	.link {
		a.active {
			color: #fa6251;
		}
	}
}

.initial {
	&::first-letter {
		color: #e9b173;
	}
}

.dropdown {
	.dropdown-button {
		background-color: #5f370b;
		border-color: rgba(166, 128, 94, 0.41);
	}

	.dropdown-content {
		background-color: #333;
		//box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		border-color: #999;

		a {
			color: @page-color;

			&:hover {
				background-color: #666;
			}

			&.active {
				background-color: #76440b;

				&:hover {
					background-color: #76613f;
				}
			}
		}
	}

	&:hover {
		.dropdown-button {
			background-color: #7d4a11;
		}

		.dropdown-content {
			display: block;
		}
	}

}

input[type=checkbox] {
	background-color: #444;
	border-color: #eee;
	box-shadow: 1px 1px 3px rgba(0,0,0,0.2);

	&:checked {
		background-color: #8B18CC;
	}

	&:disabled {
		background-color: #999;
		border-color: #eee;
	}
}


.rosaryBlue {
	color: #5b82ea;
}

.rosaryGreen {
	color: #4ab43a;
}

.search .result .header {
	background-color: #402c22;
	border-color: #77604a;
	color: #d2c196;
}

.textBox, .prayerBox.border {
	border-color: #9b7e54;
}

.starOpacity, .starOpacity2, .starRow {
	opacity: 0.9;
	filter: saturate(3);

	.blue1, .blue2, .blue3, .red1, .red2, .red3 {
		filter: saturate(4);
	}
}

img.holyPicture {
	&:not(.noBorder) {
		border-color: #825711;
		background: #5e3e07;
	}
}

img.picture, .video-container, video.video {
	&:not(.noBorder) {
		&.frame {
			&.picture:not(.transparent) {
				background: #433317;
			}
		}
	}
}

ul.heart-1 li > span, ul.heart-1 li > b,
ul.rose-1 li > span, ul.rose-1 li > b,
ul.psm-list li > span, ul.psm-list li > b,
ol li > span,
ol li > b {
	color: @page-color;
}

button, input[type=button], input[type=submit], input[type=reset] {
	border: 1pt solid #666;
	border-radius: 3pt;
	padding: 3px 7px;
	background: #eee;
	color: #000;

	&:active {
		background: #ddd;
	}
}
